const userInfo = {
	state: {
		  userInfo:{}
	},
	getters: {
		  USER_INFO(state){
			  if(localStorage.getItem('USER_INFO')){
				  state.userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
			  }
			  return state.userInfo
		  },
		  STR_ROUDOM(){
			  //生成随机32位字符串
			let str = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
				let num = '';
			    for (let i = 0; i < 32; i++) {
			        num += str.charAt(Math.floor(Math.random() * str.length));
			    };  
			return num
		  }
	},
	mutations: {
		  USER_INFO(state,value){
			  localStorage.setItem('USER_INFO',JSON.stringify(value))
			  return state.userInfo = value
		  }
	},
	actions: {
		  // SET_USER_INFO(state,value){
			 //  state.commit('USER_INFO',value)
		  // }
	},
}

export default userInfo