//验证/校检方法

//登录/注册校检
export default function test(value,type,alert){
	if(type=='text'){
		if(value==''||value==undefined){
			this.$alert(alert,'提示')
			return false
		}else{
			return true
		}
	}
	if(type=='username'){
		if(value==''||value==undefined){
			this.$alert('用户名不能为空','提示')
			return false
		}else if(!/^[\u4e00-\u9fa5]{2,4}$/.test(value)){
			this.$alert('用户名应在2~4位','提示')
			return false
		}else{
			return true
		}
	}
	if(type=='password'||type=='pwd'){
		if(value==''||value==undefined){
			this.$alert('密码不能为空','提示')
			return false
		}else if(value.length<8||value.length>16){
			this.$alert('密码的长度不能小于8位大于16位','提示')
			return false
		}else{
			return true
		}
	}
	if(type=='passwords'||type=='pwds'){
		if(value[1]==''||value[1]==undefined){
			this.$alert('确认密码不能为空','提示')
			return false
		}else if(value[0]!=value[1]){
			this.$alert('两次填写的密码不一致','提示')
			return false
		}else{
			return true
		}
	}
	if(type=='code'){
		if(value==''||value==undefined){
			this.$alert('请输入验证码','提示')
			return false
		}else{
			return true
		}
	}
	if(type=='codes'){
		if(value[1]==''||value[1]==undefined){
			this.$alert('请输入验证码','提示')
			return false
		}else if(value[0]!=value[1]){
			this.$alert('验证码错误','提示')
			return false
		}else{
			return true
		}
	}
	if(type=='img'){
		if(value==''||value.length==0){
			this.$alert('请至少上传一张图片','提示')
			return false
		}else{
			return true
		}
	}
	if(type=='tel'){
		if(value==''||value==undefined){
			this.$alert('手机号不能为空','提示')
			return false
		}else if(!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)){
			this.$alert('手机号不符合规范','提示')
			return false
		}else{
			return true
		}
	}
	if(type=='phone'){
		if(value==''||value==undefined){
			this.$alert('电话不能为空','提示')
			return false
		}else if(!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)){
			this.$alert('电话不符合规范','提示')
			return false
		}else{
			return true
		}
	}
	if(type=='idCards'){
		var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
		if(value==''||value==undefined){
			this.$alert('身份证号不能为空','提示')
			return false
		}else if(!p.test(value)){
			this.$alert('身份证号不符合规范','提示')
			return false
		}else{
			return true
		}
	}
}