import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from'./i18n/index';
import element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//注册要用的组件
import { version } from 'ant-design-vue';
import Antd from 'ant-design-vue';
console.log('ant-design-vue version:', version);
import { DatePicker } from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);
Vue.use(DatePicker);

// import axios from 'axios';
// Vue.prototype.axios = axios
// Vue.use(axios);

//挂载的额外方法
import eagm from './config/eagm.js'

//声明并挂在验证方法test
import test from './test/test.js';
Vue.prototype.$test = test;

Vue.use(element)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
