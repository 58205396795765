//该文件封装额外的全局方法
import Vue from 'vue';
//时间戳确保下标为10位
function newTime (time,type) {
	time = String(time).slice(0,10)
	return time
}

//时间戳转换日期格式
function timeDate (time) {
   var Y,M,D,h,m,s;
   var date = new Date(time * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
   Y = date.getFullYear() + '-';
   M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
   D = date.getDate() + ' ';
   h = date.getHours() + ':';
   m = date.getMinutes() + ':';
   s = date.getSeconds();
   return Y+M+D+h+m+s;
}

//获取当前时间戳
function newDate () {
	return String(Math.round(new Date()/1000))
}

//生成32位随机字符串
function RandomString () {
	let str = 'abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	let num = '';
    for (let i = 0; i < 32; i++) {
        num += str.charAt(Math.floor(Math.random() * str.length));
    };
	return num
}

//创建一个时效一天的key值
function newKey (key,data,prescription) {
	if(typeof data == 'number'||typeof data == 'string'){
		let datas = data;
		data = {};
		data.data = datas;
	}
	prescription = prescription?prescription:6*60*60*1000;
	let time = Math.round(new Date());
	let start = Math.round(time/1000);
	let end =  Math.round((time + prescription)/1000);
	data.start_time = timeDate(start);
	data.end_time = timeDate(end);
	data.prescription_time = '时效'+prescription/60/60/1000+'小时';
	uni.setStorageSync(key,data);
	setTimeout(()=>{
		uni.removeStorageSync(key)
		return
	},prescription)
}


Vue.prototype.$newTime = newTime;
Vue.prototype.$timeDate = timeDate;
Vue.prototype.$newDate = newDate;
Vue.prototype.$RandomString = RandomString;
Vue.prototype.$newKey = newKey;

let eagm = {
	newTime,
	newDate,
	timeDate,
	RandomString,
	newKey
	
}
export default eagm