import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: () => import('../views/index/PingTaiDengLuWangZhanHeSheHuiGongZhiYeMian-3'),
		children: [
			{
				path: '/',
				name: 'index',
				component: () => import('../views/index/index')
			},
			{
				path: '/complaint',
				name: 'complaint',
				component: () => import('../views/index/TouSuJianYi-1')
			},
			{
				path: '/more',
				name: 'more',
				component: () => import('../views/index/GengDuo')
			},
			{
				path: '/moredetail',
				name: 'moredetail',
				component: () => import('../views/index/PingTaiDengLuWangZhanHeSheHuiGongZhiYeMian-4')
			},
			{
				path: '/qiye',
				name: 'qiye',
				component: () => import('../views/index/qiye')
			},
			{
				path: '/dianti',
				name: 'dianti',
				component: () => import('../views/index/dianti')
			},
			{
				path: '/Shgz',
				name: 'Shgz',
				component: () => import('../views/index/Shgz')
			},
			// {
			// 	path: '/shgz',
			// 	name: 'shgz',
			// 	component: () => import('../views/index/shgz')
			// },
			{
				path: '/notice',
				name: 'notice',
				component: () => import('../views/index/notice')
			},
			{
				path: '/noticeDetail',
				name: 'noticeDetail',
				component: () => import('../views/index/noticeDetail')
			},
			{
				path: '/combuss',
				name: 'combuss',
				component: () => import('../views/index/combuss')
			},
			{
				path: '/combussDetail',
				name: 'combussDetail',
				component: () => import('../views/index/combussDetail')
			},
			{
				path: '/check',
				name: 'check',
				component: () => import('../views/index/check')
			},
			{
				path: '/product',
				name: 'check',
				component: () => import('../views/index/product')
			}
			,
			{
				path: '/indexDetail',
				name: 'indexDetail',
				component: () => import('../views/index/indexDetail')
			}
			,
			{
				path: '/shgzDetail',
				name: 'shgzDetail',
				component: () => import('../views/index/shgzDetail')
			}
		]
	},
	{
		path: '/login',
		name: 'login1',
		component: () => import('../views/login/DengLu-0')
	},
	{
		path: '/logins',
		name: 'login2',
		component: () => import('../views/login/DengLu-1')
	},
	{
		path: '/register1',
		name: 'register',
		component: () => import('../views/login/TianXieXinXi-3')
	},
	{
		path:'/register',
		name:'register',
		component: () => import('../views/register/Register.vue')
	},
	{
		path:'/RegisterResult',
		name:'RegisterResult',
		component: () => import('../views/register/RegisterResult.vue')
	},
	{
		path:'*',
		name:'404',
		component: () => import('../views/exception/404.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
