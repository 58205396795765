import Vue from "vue"
import VueI18n from 'vue-i18n'
import cn from './lang/cn.js'
import en from './lang/en.js'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'cn', 
  messages:{
	  'cn':cn,
	  'en':en
  }
});

export default i18n